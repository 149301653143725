import './Card.css';

const Card = ({ image, projectName, techList, description }) => {
  return (
    <div className='flip-card'>
      <div className='flip-card-inner'>
        <div className='flip-card-front'>
          <img src={image} alt='DemoImage'></img>
        </div>
        <div className='flip-card-back'>
          <h1>{projectName}</h1>
          <p>Tech Used: {techList}</p>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;

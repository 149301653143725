import Card from './Card';
import './CardShowcase.css';

import image from '../FingerTwister.gif';
import image2 from '../CryptoSim.gif';

const CardShowcase = () => {
  return (
    <div>
      <span className='br'></span>
      <h2>My Projects:</h2>
      <div className='card-grid-container'>
        <Card
          image={image}
          projectName='Finger Twister'
          techList='HTML,CSS,JavaScript'
          description='A simple twister game that uses your fingers. Built using event handlers and utilizing some logic to determine when a mistake was made.'
        />
        <Card
          image={image2}
          projectName='CryptoCurrency Simulator'
          techList='Django, Python, API Calls'
          description='A Cryptocurrency simulator using Django, took this project down due to server costs. Allowed a user to create an account and track purchases made in the crypto market over time.'
        />
      </div>
    </div>
  );
};

export default CardShowcase;

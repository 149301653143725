import './App.css';
import CardShowcase from './components/CardShowcase';
import Header from './components/Header';

function App() {
  return (
    <div className='App'>
      <Header />
      <CardShowcase />
    </div>
  );
}

export default App;
